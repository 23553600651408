export const apiEndpoints = {
  auth: {
    SIGN_IN: '/users/login/',
  },
  user: {
    PROFILE: '/users/profile/',
    REFRESH_TOKEN: '/refresh-token',
  },
  vendors: {
    LISTING: '/vendors/list/',
    CREATE: '/vendors/create/',
    DELETE: '/vendors/delete/',
    UPDATE: '/vendors/update/',
    DOCUMENTS_LISTING: vendorId => `/vendors/documents/list/${vendorId}`,
    DELETE_DOCUMENT: (vendorId, documentId) => `/vendors/document/delete/${vendorId}/${documentId}`,
    UPLOAD_FILE: '/vendors/document/upload/',
    CREATE_DOCUMENT: '/vendors/document/update/',
  },
  ProcessedDocuments: {
    LISTING: '/vendors/document/processed/list/',
  },
  documentSetup: {
    START_TRAINING: '/vendors/training-job/start/',
  },
  uploadNew: {
    PUSH_TO_SQUID_ERP: '/start/training/',
  },
};
