import { ROUTES } from '@routes';

export default class ModalHelpers {
  static getUploadNewModalProps = (formik, setIsModalOpen, navigate) => {
    if (formik.values.selectedVendor?.documentCount < 10) {
      return {
        textPrimary: 'Go to Document Setup',
        heading: 'Insufficient Samples',
        text: 'Each vendor requires at least 10 samples. Please add more and try again!',
        firstBtnClick: () => navigate(ROUTES.DOCUMENT_SETUP),
      };
    } else if (formik.values.selectedVendor?.trained === false) {
      return {
        textPrimary: 'Go to Document Setup',
        heading: 'Model not trained',
        text: 'Model not trained. Please train the model first and try again.',
        firstBtnClick: () => navigate(`${ROUTES.DOCUMENT_SETUP}?vendorId=${formik.values.selectedVendor?.value}`),
      };
    } else if (formik.values.selectedVendor?.processing) {
      return {
        textPrimary: 'Close',
        heading: 'Training is underway. Try again later.',
        firstBtnClick: () => setIsModalOpen(false),
      };
    }
  };
}
