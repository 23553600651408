import { useState } from 'react';
import { ReUploadFile } from '@common/components/ReUploadFile';
import { ShowErrorMessage } from '@common/components/ShowErrorMessage';
import { Button, Drawer } from 'antd';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import TableHelpers from '../../../helpers/tableHelpers';
import { CustomTable, PdfViewer, UploadFile } from '@common/components';
import { apiEndpoints } from '@services';
import { DividerIcon } from '@assets/svgs';

export const DocumentDrawer = ({
  title,
  btnText,
  open,
  onClose,
  formik,
  isReadOnly = false,
  hasModelTrainingTable = false,
  showReUploadButton = true,
}) => {
  const [uploadedFile, setUploadedFile] = useState({
    url: null,
    id: null,
    status: 'idle',
    progress: 0,
  });

  return (
    <Drawer
      title={title}
      width='100%'
      onClose={onClose}
      open={open}
      className='bg-red-500'
      extra={
        btnText && (
          <div className='flex gap-2'>
            <Button onClick={onClose} className='border-none'>
              Cancel
            </Button>
            <Button type='primary' onClick={formik.handleSubmit}>
              {btnText}
            </Button>
          </div>
        )
      }
    >
      <PanelGroup direction='horizontal' className='items-center gap-2'>
        <Panel
          defaultSize={40}
          minSize={20}
          className='flex h-full w-full flex-col rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
        >
          <div className='mb-4 flex items-center justify-between'>
            <h3>Input File</h3>
            {showReUploadButton && <ReUploadFile formik={formik} />}
          </div>
          <div className='flex w-[86%] flex-1 items-center'>
            {formik.values.file === null ? (
              <div className='w-full flex-row'>
                <UploadFile
                  className={`mx-[2.8125rem] ${formik.errors.file && 'border-warning'}`}
                  payloadFileKey='document'
                  apiUrl={apiEndpoints.vendors.UPLOAD_FILE}
                  setUploadedFile={setUploadedFile}
                  uploadedFile={uploadedFile}
                  onSuccess={res => formik.setFieldValue('file', res?.data)}
                  extendPayload={{ vendor: formik.values.selectedVendor?.value, document_type: 'training_document' }}
                />
                <ShowErrorMessage
                  className='mx-[2.8125rem] mt-2'
                  hasError={formik.errors.file}
                  message={formik.errors.file}
                />
              </div>
            ) : (
              <PdfViewer url={formik.values.file?.document} />
            )}
          </div>
        </Panel>
        <div className='relative'>
          <DividerIcon />
          <PanelResizeHandle className='absolute top-0 z-10 h-10 w-10' />
        </div>
        <Panel
          defaultSize={60}
          minSize={20}
          className='h-full w-full rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
        >
          <h3 className='mb-4'>Output File</h3>
          {hasModelTrainingTable ? (
            <CustomTable formik={formik} isReadOnly={isReadOnly} columns={TableHelpers.modelColumns} />
          ) : (
            <CustomTable formik={formik} isReadOnly={isReadOnly} columns={TableHelpers.documentColumns} />
          )}
        </Panel>
      </PanelGroup>
    </Drawer>
  );
};
