import { Button } from 'antd';
import { EyeIcon } from 'lucide-react';

export const DocumentsAction = ({ document, formik, setOpen }) => {
  const handleClick = async () => {
    setOpen(true);

    if (document?.isUserUpdatedData) {
      await formik.setFieldValue('documentTrainingTableData', document.userModifiedData);
    } else {
      await formik.setFieldValue('documentTrainingTableData', document.documentData);
    }

    await formik.setFieldValue('file', {
      document: document.document,
      vendor: document.vendor.name,
    });
  };

  return (
    <div className='flex items-center gap-3 pl-4'>
      <Button
        onClick={handleClick}
        className={'h-min border-none border-primary !p-0 px-4 py-2.5 font-medium text-primary'}
      >
        <EyeIcon color='#1565D8' className='h-5 w-5' />
      </Button>
    </div>
  );
};
