import { useState } from 'react';
import { DocumentDrawer } from '@pages/documentSetup/components/DocumentDrawer';
import { Table } from 'antd';
import { DocumentsAction } from './components/DocumentsAction';
import GenericHelpers from '../../helpers/genericHelpers';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, queryKeys, useGetQuery } from '@services';
import { PROCESSED_DOCUMENT_COLUMNS } from '@constants';

const initialValues = {
  file: null,
  documentTrainingTableData: [],
};

const ProcessedDocument = () => {
  const [open, setOpen] = useState(false);

  const formik = useFormikForm(initialValues);
  const { data: ProcessedDocuments } = useGetQuery(
    [queryKeys.PROCESSED_DOCUMENTS],
    apiEndpoints.ProcessedDocuments.LISTING
  );

  const vendorData =
    ProcessedDocuments?.data?.results?.map(document => ({
      key: document.id,
      Timestamp: GenericHelpers.formatTimestamp(document.created),
      Vendor: <p className='pl-4'>{document.vendor.name}</p>,
      InputFile: <p className='pl-4'>{document.name}</p>,
      Action: <DocumentsAction document={document} formik={formik} setOpen={setOpen} />,
    })) || [];

  return (
    <>
      <h3 className='px-6 py-8'>Document List</h3>
      <Table
        sticky
        className='h-[calc(100vh-180px)] overflow-y-auto'
        pagination={false}
        columns={PROCESSED_DOCUMENT_COLUMNS}
        dataSource={vendorData}
      />
      <DocumentDrawer
        title={'View Document'}
        open={open}
        onClose={() => setOpen(false)}
        formik={formik}
        isReadOnly={true}
        showReUploadButton={false}
      />
    </>
  );
};

export default ProcessedDocument;
