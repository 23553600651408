export const SparkleIcon = ({ className }) => {
  return (
    <svg
      width='14'
      height='12'
      viewBox='0 0 14 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M5.99984 1.33333L4.33317 5L0.666504 6.66667L4.33317 8.33333L5.99984 12L7.6665 8.33333L11.3332 6.66667L7.6665 5L5.99984 1.33333ZM11.3332 0L10.4998 1.83333L8.6665 2.66667L10.4998 3.5L11.3332 5.33333L12.1665 3.5L13.9998 2.66667L12.1665 1.83333L11.3332 0Z'
        fill='white'
      />
    </svg>
  );
};
