import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'antd';
import { DeleteModal } from '@common/components';
import { apiEndpoints, queryKeys } from '@services';
import { EditFilled } from '@assets/svgs';

export const DocumentsAction = ({ document, formik, setOpen }) => {
  const queryClient = useQueryClient();

  const handleEditClick = async () => {
    setOpen(true);
    await formik.setFieldValue('documentTrainingTableData', []);
    await formik.setFieldValue('documentTrainingTableData', document.documentData);
    formik.setFieldValue('file', {
      document: document.document,
      id: document.id,
      vendor: document.vendor,
    });
    formik.setFieldValue('headerInfo', {
      btnText: 'Update',
      headerText: 'Edit Document',
    });
  };

  return (
    <div className='flex items-center gap-3 pl-4'>
      <Button
        onClick={handleEditClick}
        className={'h-min border-none border-primary !p-0 px-4 py-2.5 font-medium text-primary'}
      >
        <EditFilled color='#1565D8' className='h-5 w-5' />
      </Button>
      <DeleteModal
        element='Sample'
        item='document'
        apiUrl={apiEndpoints.vendors.DELETE_DOCUMENT(formik.values?.selectedVendor?.value, document.id)}
        deleteSuccessMessage={'Vendor\'s document deleted Successfully.'}
        queryKey={[queryKeys.VENDOR_DOCUMENTS, formik.values?.selectedVendor?.value]}
        onDeleteSuccess={() => {
          queryClient.invalidateQueries({ queryKey: [queryKeys.VENDORS] });
        }}
      />
    </div>
  );
};
