import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getNameInitials } from '@cn';
import { Avatar, AvatarImage, AvatarFallback } from '@ui';
import { AppContext } from '@useContext';
import { routesTitle } from '@constants';

export const Header = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  const getHeaderTitle = pathName => {
    return Object.keys(routesTitle).find(route => pathName.includes(route))
      ? routesTitle[Object.keys(routesTitle).find(route => pathName.includes(route))]
      : 'Upload New';
  };

  return (
    <div className='grid grid-cols-12 gap-x-6 px-8 py-3.5'>
      <h2 className='col-span-6 text-2xl'>{getHeaderTitle(location.pathname)}</h2>
      <Avatar className='col-start-12 ml-auto'>
        <AvatarImage src='' alt='@shadcn' />
        <AvatarFallback className='bg-SelectionColor'>
          {getNameInitials(user?.firstName, user?.lastName)}
        </AvatarFallback>
      </Avatar>
    </div>
  );
};
