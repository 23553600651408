import { useRef } from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import { ShowToast } from './ShowToast';
import { useUploadFileMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, INFO, SUCCESS } from '@constants';
import { FileUploadIcon } from '@assets/svgs';

export const UploadFile = ({
  className,
  apiUrl,
  setUploadedFile,
  payloadFileKey = 'file',
  extendPayload = {},
  onSuccess = () => null,
  disabled = false,
  beforeUpload = () => true,
  label = 'Upload File',
}) => {
  const fileInputRef = useRef(null);
  const handleDrag = e => e.preventDefault();

  const { mutate: uploadFile, isPending } = useUploadFileMutation(
    apiUrl,
    onFileUploadSuccess,
    error => {
      ShowToast(ERROR, error?.response?.data?.document || DEFAULT_ERROR_MESSAGE);
      setUploadedFile({
        url: null,
        id: null,
        status: 'idle',
        progress: 0,
        loading: false,
      });
    },
    setUploadedFile
  );

  const validateFileType = file => {
    const allowedTypes = ['application/pdf'];
    return allowedTypes.includes(file.type);
  };

  const handleFileUpload = file => {
    if (beforeUpload() === false) return;

    if (!validateFileType(file)) {
      ShowToast(INFO, 'Only PDF files are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append(payloadFileKey, file);

    if (!isEmpty(extendPayload)) {
      Object.entries(extendPayload).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }

    setUploadedFile({
      url: null,
      id: null,
      status: 'idle',
      progress: 0,
      loading: false,
    });

    uploadFile(formData);
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const { files } = e.dataTransfer;
    handleFileUpload(files[0]);
  };

  const handleButtonClick = () => {
    if (fileInputRef.current && beforeUpload() !== false) {
      if (beforeUpload() === false) return;
      fileInputRef.current.click();
    }
  };

  function onFileUploadSuccess(res) {
    ShowToast(SUCCESS, 'File uploaded successfully.');
    setUploadedFile({
      url: res?.data?.document,
      id: res?.data?.id,
      status: 'completed',
      progress: 100,
      loading: false,
    });
    onSuccess(res);
  }

  return (
    <div
      className={`file-drop-area relative flex w-full flex-col items-center rounded-3xl border-2 border-dashed border-primary bg-lightGray py-[3.125rem] ${className}`}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
      onDragOver={handleDrag}
    >
      <div className='mb-6 flex h-20 w-20 items-center justify-center rounded-full bg-white'>
        <FileUploadIcon />
      </div>
      <h2>Drag your document here</h2>
      <div className='my-4 flex items-center gap-4'>
        <div className='h-[0.0625rem] w-[4.9063rem] bg-silverBlue'></div>
        OR
        <div className='h-[0.0625rem] w-[4.9063rem] bg-silverBlue'></div>
      </div>
      <div className='relative flex w-full justify-center'>
        <Button
          className='mx-auto h-[3.125rem] w-[22rem] rounded-lg border-primary bg-lightGray text-primary'
          onClick={handleButtonClick}
          disabled={isPending || disabled}
        >
          {isPending ? 'Uploading...' : label}
        </Button>
        <input
          ref={fileInputRef}
          type='file'
          className='absolute left-0 top-0 h-[3.125rem] w-[22rem] opacity-0'
          onChange={e => {
            const file = e.target.files[0];
            if (file) {
              handleFileUpload(file);
              e.target.value = '';
            }
          }}
          disabled={isPending || disabled}
        />
      </div>
      <p className='mt-3 text-sm font-normal leading-[1.125rem]'>Support Format PDF File only</p>
    </div>
  );
};
