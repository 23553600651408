import { Table } from 'antd';
import { AddVendorModal, VendorAction } from './components';
import { apiEndpoints, queryKeys, useGetQuery } from '@services';
import { VENDOR_COLUMNS } from '@constants';

const Vendors = () => {
  const { data: vendors, isLoading } = useGetQuery([queryKeys.VENDORS], apiEndpoints.vendors.LISTING);

  const vendorData =
    vendors?.data?.results?.map(vendor => ({
      key: vendor.id,
      Name: vendor.name,
      Action: <VendorAction vendor={vendor} />,
    })) || [];

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className='flex items-center justify-between px-6 py-5'>
            <h3>Vendors List</h3>
            <AddVendorModal headingText='Add Vendor' textSecondary='Save' btnText='Add Vendors' />
          </div>
          <Table
            sticky
            className='h-[calc(100vh-180px)] overflow-y-auto'
            columns={VENDOR_COLUMNS}
            dataSource={vendorData}
            pagination={false}
          />
        </>
      )}
    </>
  );
};

export default Vendors;
