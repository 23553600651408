import { createContext, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { apiEndpoints, queryKeys, useGetQuery } from '@services';
import { getLocalStorageItem } from '@utils';
import { ACCESS_TOKEN } from '@constants';

export const AppContext = createContext();
const REFETCH_INTERVAL_TIME = 3600000;

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const {
    data: userProfile,
    isFetching: isUserFetching,
    refetch: refetchUser,
  } = useGetQuery(
    [queryKeys?.USER],
    apiEndpoints?.user?.PROFILE,
    {},
    {
      enabled: !!getLocalStorageItem(ACCESS_TOKEN),
    }
  );

  useEffect(() => {
    if (!isEmpty(userProfile)) setUser(userProfile?.data);
  }, [userProfile]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getLocalStorageItem(ACCESS_TOKEN)) {
        refetchUser();
      }
    }, REFETCH_INTERVAL_TIME);

    return () => clearInterval(intervalId);
  }, []);

  return <AppContext.Provider value={{ user, setUser, isUserFetching, refetchUser }}>{children}</AppContext.Provider>;
};
