import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { CreateDocumentValidationSchema } from '@common/validators/VendorsValidationSchema';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Table } from 'antd';
import { CirclePlus } from 'lucide-react';
import { DocumentsAction } from './components';
import { DocumentDrawer } from './components/DocumentDrawer';
import { StartTraining } from './components/StartTraining';
import GenericHelpers from '../../helpers/genericHelpers';
import { CustomSelect } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { VendorDropdownValidationSchema } from '@common/validators';
import { apiEndpoints, queryKeys, useGetQuery, useUpdateMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, DOCUMENT_SETUP_COLUMNS, ERROR, SUCCESS } from '@constants';

const initialValues = {
  selectedVendor: null,
  file: null,
  documentTrainingTableData: [],
  headerInfo: {
    btnText: 'Save',
    headerText: 'Add Document',
  },
  documentsCount: 0,
};

export const DocumentSetup = () => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);

  const formik = useFormikForm(initialValues, handleDocumentDrawer, validationSchema);

  const { data: vendors } = useGetQuery([queryKeys.VENDORS], apiEndpoints.vendors.LISTING);
  const { data: vendorDocuments } = useGetQuery(
    [queryKeys.VENDOR_DOCUMENTS, formik.values?.selectedVendor?.value],
    apiEndpoints.vendors.DOCUMENTS_LISTING(formik.values?.selectedVendor?.value),
    {},
    { enabled: formik.values?.selectedVendor !== null }
  );

  const { mutate: updateDocument } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT,
    () => {
      ShowToast(SUCCESS, 'Document updated successfully.');
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: [queryKeys.VENDORS] });
    },
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.VENDOR_DOCUMENTS, formik.values?.selectedVendor?.value] }
  );

  const vendorData =
    vendorDocuments?.data?.results?.map((document, index) => ({
      key: document.id,
      sampleNo: `Sample ${index + 1}`,
      inputFiles: <p className='pl-4'>{document.name}</p>,
      Action: <DocumentsAction document={document} formik={formik} setOpen={setOpen} />,
    })) || [];

  function handleDocumentDrawer() {
    if (open) {
      const data = { documentData: formik.values?.documentTrainingTableData };
      updateDocument({ data, id: formik.values?.file?.id });
    } else {
      formik.setFieldValue('documentTrainingTableData', []);
      formik.setFieldValue('file', null);
      formik.setFieldValue('headerInfo', {
        btnText: 'Save',
        headerText: 'Add Document',
      });
      setOpen(true);
    }
  }

  function validationSchema() {
    return open ? CreateDocumentValidationSchema : VendorDropdownValidationSchema;
  }

  return (
    <>
      <div className='flex items-center justify-between px-6 py-5'>
        <h3>Setup List</h3>
        <div className='flex gap-4'>
          <CustomSelect
            setSelected={vendor => formik.setFieldValue('selectedVendor', vendor)}
            options={GenericHelpers.convertToSelectOptions(vendors?.data?.results)}
            hasError={formik.errors.selectedVendor}
            message={formik.errors.selectedVendor}
          />
          <Button
            onClick={formik.handleSubmit}
            className='h-[2.375rem] border-primary px-4 py-2.5 font-medium text-primary'
          >
            <CirclePlus color='#1565D8' className='h-4 w-4' />
            Add Document
          </Button>
          <DocumentDrawer
            title={formik.values.headerInfo.headerText}
            btnText={formik.values.headerInfo.btnText}
            open={open}
            onClose={() => setOpen(false)}
            formik={formik}
            hasModelTrainingTable={true}
          />
          <StartTraining formik={formik} vendorDocumentsCount={vendorDocuments?.data.count} />
        </div>
      </div>
      <Table
        sticky
        className='h-[calc(100vh-180px)] overflow-y-auto'
        pagination={false}
        columns={DOCUMENT_SETUP_COLUMNS}
        dataSource={vendorData}
      />
    </>
  );
};
