import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { Button } from 'antd';
import { CustomModal } from '@common/components';
import { apiEndpoints, usePostMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, INFO } from '@constants';
import { SparkleIcon } from '@assets/svgs';

export const StartTraining = ({ formik, vendorDocumentsCount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: startTraining } = usePostMutation(
    apiEndpoints.documentSetup.START_TRAINING,
    () => ShowToast(INFO, 'Training is started.'),
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE)
  );

  return (
    <>
      <Button
        type='primary'
        onClick={() => {
          formik.values?.selectedVendor === null
            ? formik.handleSubmit()
            : vendorDocumentsCount < 10
              ? setIsModalOpen(true)
              : startTraining({ id: formik.values?.selectedVendor?.value });
        }}
        onCancel={() => setIsModalOpen(false)}
        className='h-[2.375rem] px-4 py-2.5 font-medium'
      >
        <SparkleIcon className='h-3.5 w-3.5' />
        Start Training
      </Button>
      <CustomModal
        open={isModalOpen}
        cancel={() => setIsModalOpen(false)}
        firstBtnClick={() => setIsModalOpen(false)}
        textClass='mt-4'
        footerDangerBtnClass='hidden'
        textPrimary='Close'
        SVG='AlertTriangleIcon'
        heading='Insufficient Samples'
        text='You need to upload at least 10 files to begin the training.'
      />
    </>
  );
};
