import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowToast } from '@common/components/ShowToast';
import { Button, Modal } from 'antd';
import { CircleArrowRight } from 'lucide-react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import GenericHelpers from '../../helpers/genericHelpers';
import ModalHelpers from '../../helpers/modalHelpers';
import TableHelpers from '../../helpers/tableHelpers';
import { CustomModal, CustomSelect, CustomTable, PdfViewer, UploadFile } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { VendorDropdownValidationSchema } from '@common/validators';
import { apiEndpoints, queryKeys, useGetQuery, useUpdateMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '@constants';
import { DividerIcon, LoaderIcon } from '@assets/svgs';

const initialValues = {
  selectedVendor: null,
  file: null,
  documentTrainingTableData: [],
};

const UploadNew = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({
    url: null,
    id: null,
    status: 'idle',
    progress: 0,
    loading: false,
  });

  const { data: vendors } = useGetQuery([queryKeys.VENDORS], apiEndpoints.vendors.LISTING);
  const formik = useFormikForm(initialValues, () => null, VendorDropdownValidationSchema);

  const { mutate: pushToSquidErp } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT,
    onPushToSquidErpSuccess,
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE)
  );

  const modalProps = ModalHelpers.getUploadNewModalProps(formik, setIsModalOpen, navigate);
  const openModal =
    formik.values.selectedVendor?.documentCount < 10 ||
    formik.values.selectedVendor?.trained === false ||
    formik.values.selectedVendor?.processing;

  function onPushToSquidErpSuccess() {
    ShowToast(SUCCESS, 'Successfully pushed to squid erp.');
    formik.resetForm();
    setUploadedFile({
      url: null,
      id: null,
      status: 'idle',
      progress: 0,
      loading: false,
    });
  }

  useEffect(() => {
    if (formik.values.selectedVendor !== null) {
      if (openModal) {
        setIsModalOpen(true);
      }
    }
  }, [formik.values.selectedVendor]);

  return (
    <>
      {formik.values.file !== null ? (
        <div className='flex h-full flex-col gap-6'>
          <div className='flex flex-1 gap-5'>
            <PanelGroup direction='horizontal' className='items-center gap-2'>
              <Panel
                defaultSize={48.5}
                minSize={20}
                className='flex h-full w-full flex-col rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
              >
                <h3 className='mb-4'>Input File</h3>
                <PdfViewer url={formik.values.file.document} />
              </Panel>
              <div className='relative'>
                <DividerIcon />
                <PanelResizeHandle className='absolute top-0 z-10 h-10 w-10' />
              </div>
              <Panel
                defaultSize={48.5}
                minSize={20}
                className='h-full w-full rounded-3xl border border-silverBlue bg-white px-6 py-[1.125rem]'
              >
                <h3 className='mb-4'>Output File</h3>
                <CustomTable formik={formik} columns={TableHelpers.documentColumns} />
              </Panel>
            </PanelGroup>
          </div>
          <div className='flex justify-end gap-4'>
            <Button onClick={() => formik.resetForm()} className='h-11'>
              Cancel
            </Button>
            <Button
              onClick={() =>
                pushToSquidErp({
                  id: formik.values?.file?.id,
                  data: { documentData: formik.values?.documentTrainingTableData },
                })
              }
              type='primary'
              className='h-11'
            >
              Push to Squid ERP <CircleArrowRight />
            </Button>
          </div>
        </div>
      ) : (
        <div className='shadow-secondary flex h-full flex-col overflow-hidden rounded-xl border border-baseColor bg-white'>
          <div className='flex items-center justify-between px-6 pt-8'>
            <div>
              <h3>Upload Document</h3>
              <p className='mt-3 text-base font-normal leading-5'>Please choose or drag a file to upload</p>
            </div>
            <CustomSelect
              setSelected={vendor => formik.setFieldValue('selectedVendor', vendor)}
              options={GenericHelpers.convertToSelectOptions(vendors?.data?.results)}
              hasError={formik.errors.selectedVendor}
              message={formik.errors.selectedVendor}
            />
          </div>
          <div className='grid flex-1 grid-cols-12 gap-x-6'>
            <div className='col-span-10 col-start-2 flex items-center'>
              <UploadFile
                className='mx-[2.8125rem]'
                payloadFileKey='document'
                apiUrl={apiEndpoints.vendors.UPLOAD_FILE}
                setUploadedFile={setUploadedFile}
                uploadedFile={uploadedFile}
                onSuccess={res => {
                  formik.setFieldValue('file', res?.data);
                  formik.setFieldValue('documentTrainingTableData', res?.data?.documentData);
                }}
                extendPayload={{ vendor: formik.values.selectedVendor?.value, document_type: 'process_document' }}
                disabled={openModal}
                beforeUpload={() => {
                  if (formik.values.selectedVendor === null) {
                    ShowToast(ERROR, 'Please select a vendor');
                    return false;
                  }
                  return true;
                }}
              />
            </div>
          </div>
          <CustomModal
            open={isModalOpen}
            cancel={() => setIsModalOpen(false)}
            SVG='AlertTriangleIcon'
            textClass='mt-4'
            footerDangerBtnClass='hidden'
            {...modalProps}
          />

          <Modal
            centered
            open={uploadedFile?.loading}
            footer={[]}
            width={400}
            closeIcon={null}
            maskClosable={false}
            className={'screenLayoutModal'}
          >
            <div className='text-center'>
              <div className='flex items-center justify-center'>
                <LoaderIcon />
              </div>
              <h3 className='mb-3 mt-[42px] text-lg font-medium text-midnightBlue'>Generating AI content</h3>
              <p className='text-sm font-normal text-bodyText'>
                {'Please don\'t close this tab, otherwise you\'ll lose your progress.'}
              </p>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default UploadNew;
