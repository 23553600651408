import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { DocumentSetup } from '@pages/documentSetup/index.js';
import { Layout } from '../Layout.jsx';
import { ProcessedDocument } from '@pages/processedDocument';
import { SignIn } from '@pages/signin';
import { UploadNew } from '@pages/uploadNew';
import { Vendors } from '@pages/vendors';
import { PrivateRoute, ROUTES, UnPrivateRoute } from '@routes';

const createPrivateRoute = Component => {
  return (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
};

const createUnPrivateRoute = Component => {
  return (
    <UnPrivateRoute>
      <Component />
    </UnPrivateRoute>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.BASE} element={createPrivateRoute(Layout)}>
        <Route index path={ROUTES.UPLOAD_NEW} element={createPrivateRoute(UploadNew)} />
        <Route path={ROUTES.DOCUMENT_SETUP} element={createPrivateRoute(DocumentSetup)} />
        <Route path={ROUTES.PROCESSED_DOCUMENTS} element={createPrivateRoute(ProcessedDocument)} />
        <Route path={ROUTES.VENDORS} element={createPrivateRoute(Vendors)} />
        <Route path={ROUTES.BASE} element={<Navigate to={ROUTES.UPLOAD_NEW} />} />
        <Route path={ROUTES.NOT_MATCHED} element={<Navigate to={ROUTES.UPLOAD_NEW} />} />
      </Route>
      <Route path={ROUTES.SIGN_IN} element={createUnPrivateRoute(SignIn)} />
      <Route path={ROUTES.NOT_MATCHED} element={<Navigate to={ROUTES.SIGN_IN} replace />} />
    </>
  ),
  { basename: '/app' }
);
