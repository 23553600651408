import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Book, ChevronLeft, ChevronRight, Inbox, LayoutGrid, LogOut, Users } from 'lucide-react';
import { ROUTES } from '@routes';
import { removeLocalStorageItem } from '@utils';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@constants';
import { Logo, ShortLogo } from '@assets/svgs';

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(localStorage.getItem('sidebarCollapsed') === 'false' ? false : true);

  const isActiveTab = tab => location.pathname?.startsWith(tab);

  const handleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', sidebarOpen);
  }, [sidebarOpen]);

  const navLinks = [
    {
      name: 'Upload New',
      href: ROUTES.UPLOAD_NEW,
      icon: className => <LayoutGrid className={className} />,
    },
    {
      name: 'Document Setup',
      href: ROUTES.DOCUMENT_SETUP,
      icon: className => <Inbox className={className} />,
    },
    {
      name: 'Processed Documents',
      href: ROUTES.PROCESSED_DOCUMENTS,
      icon: className => <Book className={className} />,
    },
    {
      name: 'Vendors',
      href: ROUTES.VENDORS,
      isNestedRoute: true,
      icon: className => <Users className={className} />,
    },
  ];

  return (
    <div
      className={` ${
        sidebarOpen ? 'w-[280px]' : 'w-[76px]'
      } relative flex min-h-screen flex-col border-r border-borderColor bg-white duration-200`}
    >
      <div className={`flex ${sidebarOpen ? 'px-6' : 'justify-center px-4'} py-6`}>
        {sidebarOpen ? <Logo /> : <ShortLogo />}
      </div>
      <nav className='flex flex-1 flex-col justify-between'>
        <ul className={`flex flex-col ${sidebarOpen ? 'px-6' : 'px-4'} gap-1 border-b border-[#D0D5DD] py-6`}>
          {navLinks.map((link, index) => {
            return (
              <li key={index}>
                <Link
                  to={link.href}
                  className={`flex items-center ${
                    sidebarOpen ? 'px-4' : 'px-3'
                  } cursor-pointer gap-3 whitespace-nowrap rounded-full py-3 text-sm font-medium hover:bg-hoverSelection ${
                    isActiveTab(link.href) ? 'bg-hoverSelection text-primary' : 'text-bodyText'
                  }`}
                >
                  {link.icon(`w-5 h-5 ${isActiveTab(link.href) ? 'stroke-primary' : 'stroke-bodyText'}`)}
                  {sidebarOpen && link.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className={`${sidebarOpen ? 'px-6' : 'px-3'} border-t border-[#D0D5DD] py-3`}>
          <div
            className='flex cursor-pointer items-center gap-4 px-4 py-3'
            onClick={() => {
              removeLocalStorageItem(ACCESS_TOKEN);
              removeLocalStorageItem(REFRESH_TOKEN);
              navigate(ROUTES.SIGN_IN);
            }}
          >
            <LogOut className='h-5 w-5 stroke-warning' />
            {sidebarOpen && <span className='text-sm font-medium text-bodyText'>Logout</span>}
          </div>
        </div>
      </nav>
      <div
        className='absolute -right-3.5 top-1/2 cursor-pointer rounded-full border border-[#D0D5DD] bg-white p-1'
        onClick={handleSidebar}
      >
        {sidebarOpen ? (
          <ChevronLeft color='#596780' className='h-4 w-4' />
        ) : (
          <ChevronRight color='#596780' className='h-4 w-4' />
        )}
      </div>
    </div>
  );
};
