import moment from 'moment';

export default class GenericHelpers {
  static convertToSelectOptions = data => {
    return data?.map(item => ({
      value: item?.id?.toString(),
      label: item?.name,
      documentCount: item?.documentCount,
      processing: item?.processing,
      trained: item?.trained,
    }));
  };

  static formatTimestamp = timestamp => {
    return moment(timestamp).format('M/DD/YYYY, hh:mmA');
  };
}
