export default class TableHelpers {
  static modelColumns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 320,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 60,
    },
  ];

  static documentColumns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 187,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 50,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 50,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 50,
    },
  ];
}
